<template>
    <div>
        <div class="header">Stages Properties Translation Management System</div>
        <div class="left">
            <el-table :data="files" highlight-current-row empty-text="No property file" @row-click="fileNameClick" :max-height="fileTableHeight" size="small">
                <el-table-column prop="name" label="Property Files">
                </el-table-column>
                <el-table-column label="Action" align="right">
                    <template slot-scope="scope">
                        <el-popconfirm 
                        title="Delete this fileName will also delete all the properties inside this file, confirm to delete?"
                        confirm-button-text="confirm"
                        cancel-button-text="cancel"
                        icon-color="red"
                        @confirm="deleteFileName(scope)"
                        >
                            <el-button slot="reference" type="danger" icon="el-icon-delete" plain size="mini"></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-input ref="addFile" v-show="showAddFile" placeholder="Please input file name..." clearable v-model="fileName" @blur="handleAddFileBlur" @clear="clear" @change="handleChange"></el-input>
            <el-button size="small" type="primary" style="margin-top: 20px; float: left;" slot="reference" @click="addPropertyFile">Add Property File</el-button>
            <el-tag size="small" style="margin-top: 25px; float: right; margin-right: 10px;" type="success">{{files.length}} files in total</el-tag>
        </div>
        <div class="right">
            <div class="right-header">
                <el-popover
                    placement="top-start"
                    width="200"
                    title="Reminder"
                    trigger="hover"
                    content="The file must be *_xx.properties, xx can be en,zh,or de !!"
                >
                    <el-upload
                    style="float: left;"
                    ref="upload"
                    action=""
                    slot="reference"
                    :disabled="uploadDisabled"
                    :on-change="openFile"
                    :before-upload="beforeUpload"
                    :on-exceed="handleExceed"
                    :auto-upload="false" 
                    :file-list="fileList">
                        <el-button size="small" type="primary" class="button" :disabled="uploadDisabled">Import Properties</el-button>
                    </el-upload>
                </el-popover>
                <el-button size="small" type="success" class="button" style="margin-left: 10px;" @click="saveToFile('en')" :disabled="uploadDisabled">Export EN Properties</el-button>
                <el-button size="small" type="success" class="button" @click="saveToFile('zh')" v-show="this.language == 'EN-ZH'" :disabled="uploadDisabled">Export ZH Properties</el-button>
                <el-button size="small" type="success" class="button" @click="saveToFile('de')" v-show="this.language == 'EN-DE'" :disabled="uploadDisabled">Export DE Properties</el-button>
                <el-select v-model="language" placeholder="Please choose language" style="float: left; margin-left: 10px; width: 100px;" size="small">
                    <el-option
                    v-for="item in languages"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
                <el-input size="small" placeholder="Search..." v-model="keyword" @input="search" clearable @clear="search" prefix-icon="el-icon-search" style="width: 200px; float: left; margin-left: 10px; margin-top: 20px;">
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
                <el-popover
                    placement="top-start"
                    title="Get help"
                    width="200"
                    trigger="hover"
                    content="Please send email to jinfei.wang@methodpark.com"
                >
                    <el-button slot="reference" type="success" icon="el-icon-user" size="small" circle>Need help?</el-button>
                </el-popover>
            </div>
            <el-table :data="selectedProperties" border stripe  empty-text="No property" ref="propertyTable" :height="propertyTableHeight" size="small">
                <el-table-column type="index" width="60" label="No.">
                </el-table-column>
                <el-table-column label="Action" width="80">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-delete" type="danger" plain size="mini" @click="deleteProperty(scope)"></el-button>
                    </template>
                </el-table-column>
                <el-table-column label="Key" prop="key" width="300" sortable></el-table-column>
                <el-table-column label="English" prop="en" sortable>
                    <!-- <template slot-scope="scope">
                        <el-input v-model="scope.row.en" clearable  @blur="handleBlur(scope)"></el-input>
                    </template> -->
                </el-table-column>
                <el-table-column label="Chinese" sortable prop="zh" v-if="this.language == 'EN-ZH'">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.zh" clearable  @blur="handlePropertyBlur(scope)" @change="handlePropertyChange(scope)" size="mini"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="German" sortable prop="de" v-if="this.language == 'EN-DE'">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.de" clearable  @blur="handlePropertyBlur(scope)" @change="handlePropertyChange(scope)" size="mini"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <el-button size="small" class="button" type="primary" @click="dialogFormVisible = true" :disabled="uploadDisabled">Add New Property</el-button>
            <el-tag class="tags">In total: {{selectedProperties.length}}</el-tag>
            <el-tag class="tags" type="success">Translated: {{selectedProperties.length - notTranslated.length}}</el-tag>
            <el-tag class="tags" type="warning">Missing Translation: {{notTranslated.length}}</el-tag>
            <el-dialog title="Add Property" :visible.sync="dialogFormVisible">
                <el-form :model="property" :rules="rules" ref="property">
                    <el-form-item label="KEY" prop="key">
                        <el-input v-model="property.key" ></el-input>
                    </el-form-item>
                    <el-form-item label="EN" >
                        <el-input v-model="property.en" type="textarea" ></el-input>
                    </el-form-item>
                    <el-form-item label="ZH">
                        <el-input v-model="property.zh" type="textarea" ></el-input>
                    </el-form-item>
                    <el-form-item label="DE">
                        <el-input v-model="property.de" type="textarea" ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel">Cancel</el-button>
                    <el-button type="primary" @click="addNewProperty">Confirm</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import http from '@/util/http.js'
export default {
    name: "StagesTrans",
    components: {
    },
    data () {
        return {
            language: 'EN-ZH',
            languages: ['EN-ZH', 'EN-DE'],
            fileTableHeight: 0,
            propertyTableHeight: 0,
            uploadedProperties: [],
            lines: [],
            rules:
                {
                    key: [
                    {required: true, message: 'please input key', trigger: 'blur'}
                    ]
                },
            dialogFormVisible: false,
            property: {
                fileName: '',
                key: '',
                en: '',
                zh: '',
                de: ''
            },
            fileName: '',
            showAddFile: false,
            keyword: '',
            fileList: [],
            files: [
            ],
            action: '',
            selectedProperties: [],
            properties: []
        }
    },
    created: function () {
        http.get('/file/getAllFiles').then(
            res => {
                if (res.data.status === 1) {
                    this.files = res.data.data.data
                } else {
                    this.$message.error(res.data.message)
                }
            }
        )
        this.fileTableHeight = "100px;"
        this.propertyTableHeight = "100px;"
    },
    computed: {
        // fileTableHeight () {
        //     return this.propertyTableHeight + 80
        // },
        uploadDisabled () {
            return this.fileName == ''
        },
        notTranslated () {
            let a = this.selectedProperties
            let la = this.language
            return a.filter(
                value => {
                    if (la == 'EN-ZH') {
                        return value.zh == null || typeof(value.zh) == 'undefined' || value.zh == ''
                    } else if (la == 'EN-DE') {
                        return value.de == null || typeof(value.de) == 'undefined' || value.de == ''
                    }
                }
            )
        }
        // selectedProperties () {
        //     let la = this.language
        //     let a = this.keyword.trim().toLowerCase()
        //     if (a == '') {
        //         return this.properties.filter(
        //             value => {
        //                 return value.key.trim().slice(0,1) != '#'
        //             }
        //         )
        //     } else {
        //         return this.properties.filter(
        //             value => {
        //                 var result1
        //                 var result2
        //                 var result3
        //                 var result4
        //                 if (value.key != null && typeof(value.key) != 'undefined') {
        //                     result1 = value.key.toLowerCase().indexOf(a) > -1
        //                 }
        //                 if (value.en != null && typeof(value.en) != 'undefined') {
        //                     result2 = value.en.toLowerCase().indexOf(a) > -1
        //                 }
        //                 if (value.zh != null && typeof(value.zh) != 'undefined') {
        //                     result3 = value.zh.toLowerCase().indexOf(a) >-1 
        //                 }
        //                 if (value.de != null && typeof(value.de) != 'undefined') {
        //                     result4 = value.de.toLowerCase().indexOf(a) >-1 
        //                 }
        //                 if (la == 'EN-ZH') {
        //                     return (result1 || result2 || result3) && value.key.trim().slice(0,1) != '#'
        //                 } else if (la == 'EN-DE') {
        //                     return (result1 || result2 || result4) && value.key.trim().slice(0,1) != '#'
        //                 }
                        
        //             }
        //         )
        //     }
        // }
    },
    mounted: function () {
        this.propertyTableHeight = window.innerHeight - this.$refs.propertyTable.$el.offsetTop - 100
        this.fileTableHeight = this.propertyTableHeight + 50
    },
    methods: {
        search () {
            let la = this.language
            let a = this.keyword.trim().toLowerCase()
            this.selectedProperties = this.properties.filter(
                value => {
                    var result1
                    var result2
                    var result3
                    var result4
                    if (value.key != null && typeof(value.key) != 'undefined') {
                        result1 = value.key.toLowerCase().indexOf(a) > -1
                    }
                    if (value.en != null && typeof(value.en) != 'undefined') {
                        result2 = value.en.toLowerCase().indexOf(a) > -1
                    }
                    if (value.zh != null && typeof(value.zh) != 'undefined') {
                        result3 = value.zh.toLowerCase().indexOf(a) >-1 
                    }
                    if (value.de != null && typeof(value.de) != 'undefined') {
                        result4 = value.de.toLowerCase().indexOf(a) >-1 
                    }
                    if (la == 'EN-ZH') {
                        return (result1 || result2 || result3) && value.key.trim().slice(0,1) != '#'
                    } else if (la == 'EN-DE') {
                        return (result1 || result2 || result4) && value.key.trim().slice(0,1) != '#'
                    }
                    
                }
            )
        },
        // export txt
        saveToFile(language) {
            if (this.fileName == '') {
                alert('Please choose a file to export.')
                return
            }
            // var title = '# ' + language
            var str=''
            if (language == 'en') {
                this.properties.forEach(
                    item => {
                        if (item.key.trim().slice(0, 1) != '#') {
                            str += item.key + ' = '+ item.en + '\r\n'
                        } else {
                            str += item.key + '\r\n'
                        }
                    }
                )
            } else if (language == 'zh') {
                this.properties.forEach(
                    item => {
                        if (item.key.trim().slice(0, 1) != '#') {
                            str += item.key + ' = '+ item.zh + '\r\n'
                        } else {
                            str += item.key + '\r\n'
                        }
                    }
                )
            } else if (language == 'de') {
                this.properties.forEach(
                    item => {
                        if (item.key.trim().slice(0, 1) != '#') {
                            str += item.key + ' = '+ item.de + '\r\n'
                        } else {
                            str += item.key + '\r\n'
                        }
                    }
                )
            }
            // var allStr = title+'\r\n'+'\r\n'+str
            var allStr = str
            var export_blob = new Blob([allStr]);
            var save_link = document.createElement("a");
            save_link.href = window.URL.createObjectURL(export_blob);
            // save_link.download = '导出'+'.txt';
            if (language == 'en') {
                save_link.download = this.fileName + '_en.properties'
            } else if (language == 'zh') {
                save_link.download = this.fileName + '_zh.properties'
            } else if (language == 'de') {
                save_link.download = this.fileName + '_de.properties'
            }
            this.fakeClick(save_link);
        },
        fakeClick(obj) {
            var ev = document.createEvent("MouseEvents");
            ev.initMouseEvent(
                "click",
                true,
                false,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            )
            obj.dispatchEvent(ev);
        },
        saveProperty (property) {
            http.post("/property/update", property).then(
                res => {
                    if (res.data.status == 1) {
                        console.log(property)
                    } else {
                        this.$message.error(res.data.message)
                    }
                }
            )
        },
        handlePropertyChange (scope) {
            this.saveProperty(scope.row)
        },
        fileNameClick (row) {
            this.property.fileName = row.name
            this.fileName = row.name
            http.post('/property/getAllProperties', {fileName: this.property.fileName}).then(
                res => {
                    if (res.data.status === 1) {
                        this.properties = res.data.data.properties
                        this.keyword = ''
                        this.search()
                    } else {
                        this.$message.error(res.data.message)
                        this.properties.splice(0, this.properties.length)
                        this.keyword = ''
                        this.search()
                    }
                }
            )
        },
        loadProperties () {
            this.properties.splice(0, this.properties.length)
            http.post('/property/getAllProperties', {fileName: this.fileName}).then(
                res => {
                    if (res.data.status === 1) {
                        this.properties = res.data.data.properties
                        this.keyword = ''
                        this.search()
                    } else {
                        this.$message.error(res.data.message)
                        this.properties.splice(0, this.properties.length)
                        this.keyword = ''
                        this.search()
                    }
                }
            )
        },
        cancel () {
            this.dialogFormVisible = false
            this.property.key = ''
            this.property.value= {}
        },
        addNewProperty () {
            this.$refs.property.validate((valid) => {
                if (valid) {
                    http.post('/property/save', this.property).then(
                        res => {
                            if (res.data.status === 1) {
                                this.dialogFormVisible = false
                                this.properties.push({key: this.property.key, en: this.property.en, zh: this.property.zh, fileName: this.fileName})
                                this.property.key = ''
                                this.property.en = ''
                                this.property.zh = ''
                            } else {
                                this.$message.error(res.data.message)
                            }
                        }
                    )
                } else {
                    return false;
                }
            });
            // 
        },

        deleteFileName (scope) {
            http.post("/property/deleteAllByFileName", {fileName: scope.row.name}).then(
                res => {
                    if (res.data.status == 1) {
                        http.post('/file/deleteByName', {name: scope.row.name}).then(
                            res => {
                                if (res.data.status === 1) {
                                    this.files.splice(scope.$index, 1)
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            }
                        )
                        this.properties.splice(0, this.properties.length)
                        this.search()
                        this.fileName = ''
                    } else {
                        this.$message.error(res.data.message)
                    }
                }
            )
        },
        handleExceed () {
            console.log("handleExceed")
        },
        beforeRemove () {
            console.log("before remove")
        },
        handleRemove () {
            console.log("handleRemove")
        },
        handlePreview () {
            console.log("handlePreview")
        },
        clear () { 
            this.fileName = ''
            this.showAddFile = false
        },
        handleChange () {
            if (this.fileName === '') {
                return
            }
            http.post('/file/save', {name: this.fileName}).then(
                    res => {
                        console.log(res)
                        if (res.data.status === 1) {
                            this.files.push({name: this.fileName})
                            this.fileName = ''
                            this.showAddFile = false
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
        },
        handleAddFileBlur () {
            if (this.fileName == '') {
                this.showAddFile = false
            }
        },
        addPropertyFile () {
            this.fileName = ''
            this.showAddFile = true
            this.$nextTick(
                this.$refs.addFile.focus()
            )
        },
        focus (scope) {
            console.log(scope)
        },
        handlePropertyBlur (scope) {
            this.saveProperty(scope.row)
        },
        deleteProperty(scope) {
            http.post('/property/deleteById', {id: scope.row.id}).then(
                res => {
                    if (res.data.status === 1) {
                        this.$message(
                            {
                                message: 'The property was deleted successfully.',
                                type: 'success'
                            }
                        )
                        this.selectedProperties.splice(scope.$index, 1)
                        this.properties.splice(this.properties.findIndex(
                            value => {
                                return value.id == scope.row.id
                            }
                        ), 1)
                    } else {
                        this.$message.error(res.data.message)
                    }
                }
            )
        },
        beforeUpload (file) {
            console.log(file)
            // console.log("this.fileName: " + this.fileName)
            // if (this.fileName == '') {
            //     alert("Please choose a property file in the left first!")
            //     return false
            // }
        },
        openFile(file) {
            let fn = file.name.split('.')
            let ll = fn[0].slice(-2)
            if (fn[1] != 'properties' || (ll != 'en' && ll != 'zh' && ll != 'de')) {
                this.$message.error('please import xxxx_xx.properties file only!')
                return
            }
            this.uploadedProperties.splice(0, this.uploadedProperties.length)
            let u = this.uploadedProperties
            let f = this.fileName
            var reader = new FileReader()
            var _this = this
            reader.onload = function () {
                if (reader.result) {
                    //打印文件内容
                    // console.log(reader.result)
                    this.lines = reader.result.split('\n')
                    if(ll == 'en') {
                        this.lines.forEach(
                            value => {
                                if (value[0] != '#') {
                                    var temp
                                    temp = value.split('=')
                                    if (temp.length == 2) {
                                        u.push({
                                            fileName: f,
                                            key: temp[0].trim(),
                                            en: temp[1].trim()
                                        })
                                    }
                                } else {
                                    u.push(
                                        {
                                            fileName: f,
                                            key: value
                                        }
                                    )
                                }
                            }
                        )
                        http.put('/property/upsertInBatch', {properties: u, language: 'en'}).then(
                            res => {
                                if (res.data.status == 1) {
                                    _this.$message({
                                        message: 'imported successfully!',
                                        type: 'success'
                                    })
                                    _this.loadProperties()
                                } else {
                                    _this.$message.error(res.data.message)
                                }
                                
                            }
                        )
                    } else if (ll == 'zh') {
                        this.lines.forEach(
                            value => {
                                if (value[0] != '#') {
                                    var temp
                                    temp = value.split('=')
                                    if (temp.length == 2) {
                                        u.push({
                                            fileName: f,
                                            key: temp[0].trim(),
                                            zh: temp[1].trim()
                                        })
                                    }
                                } else {
                                    u.push(
                                        {
                                            fileName: f,
                                            key: value
                                        }
                                    )
                                }
                            }
                        )
                        http.put('/property/upsertInBatch', {properties: u, language: 'zh'}).then(
                            res => {
                                if (res.data.status == 1) {
                                    _this.$message({
                                        message: 'imported successfully!',
                                        type: 'success'
                                    })
                                    _this.loadProperties()
                                } else {
                                    _this.$message.error(res.data.message)
                                }
                                
                            }
                        )
                    } else if (ll == 'de') {
                        this.lines.forEach(
                            value => {
                                if (value[0] != '#') {
                                    var temp
                                    temp = value.split('=')
                                    if (temp.length == 2) {
                                        u.push({
                                            fileName: f,
                                            key: temp[0].trim(),
                                            de: temp[1].trim()
                                        })
                                    }
                                } else {
                                    u.push(
                                        {
                                            fileName: f,
                                            key: value
                                        }
                                    )
                                }
                            }
                        )
                        http.put('/property/upsertInBatch', {properties: u, language: 'de'}).then(
                            res => {
                                if (res.data.status == 1) {
                                    _this.$message({
                                        message: 'imported successfully!',
                                        type: 'success'
                                    })
                                    _this.loadProperties()
                                } else {
                                    _this.$message.error(res.data.message)
                                }
                                
                            }
                        )
                    }
                }
            }
            reader.readAsText(file.raw, "utf-8")
            // reader.readAsText(file.raw)
        }
    }
}
</script>
<style scoped>
.header{
    height: 50px;
    line-height: 50px;
    width: 100%;
    background: orange;
    font-size: 20px;
    font-weight: bold;
    float: left;
}
.left{
    /* position: absolute; */
    /* top: 60px; */
    height: 100%;
    width: 20%;
    text-align: left;
    float: left;
    font-size: 20px;
    padding-top: 15px;
    /* border-right: grey solid 0.1px; */

}
.right{
    /* position: absolute; */
    width: 79%;
    float: right;
    min-height: 500px;
    /* top: 60px; */
    right: 0px;
    height: 100%;
    overflow: scroll;
}
.right-header{
    width: 100%;
    height: 75px;
    line-height: 75px;
    float: left;
    /* background: red; */
}
.button{
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
}
.item{
    width: 10%;
    float: left;
}
.value{
    width: 45%;
    float: left;
}
.tags{
    float: left;
    margin-top: 20px;
    margin-left: 20px;
}
</style>
